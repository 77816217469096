import { get, post, put, del } from "../lib/http";

class SerieService {
  static SERIES_PROGRESS = 14;
  static SERIES_NEW = 15;
  static SERIES_SEEN = 16;

  getSerieById(id) {
    return get(`/series/${id}`);
  }

  getSeries(page, sortBy, sortDirection) {
    return get(`/series`, {
      params: { page: page, sortBy: sortBy, sortDirection: sortDirection },
    });
  }

  getSeriesByType(type, page, name) {
    return get(`/series/type/${type}`, {
      params: { name: name, page: page },
    });
  }

  addSerie(serie) {
    return post("/series", serie);
  }

  updateSerie(serie) {
    return put(`/series/${serie.id}`, serie);
  }

  promoteSerie(id) {
    return put(`/series/${id}/promote`);
  }

  deleteSerie(id) {
    return del(`/series/${id}`);
  }
}

export default SerieService;
