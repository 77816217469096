import React, { useState } from "react";
import "../../styles/signin.css";
import { useNavigate } from "react-router-dom";
import AuthenticationService from "../../services/AuthenticationService";
import { useDispatch } from "react-redux";

function SignIn() {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function handleSubmit(e) {
    e.preventDefault();

    setIsLoading(true);

    const authenticationService = new AuthenticationService();
    authenticationService
      .login(e.target.username.value, e.target.password.value)
      .then((response) => {
        dispatch({
          type: "LOGIN",
          data: {
            ...response.data,
            ...{ rememberMe: e.target.rememberMe.checked },
          },
        });
        navigate("/films/downloadlijst");
      })
      .catch((error) => {
        setError(error.response.data.error.message);
        setIsLoading(false);
      });
  }

  return (
    <div className="signin vw-100">
      <div className="form-signin">
        <form method="POST" onSubmit={handleSubmit}>
          <h1 className="h3 mb-3 fw-normal text-center">Inloggen</h1>

          {error && (
            <div className="alert alert-danger" role="alert">
              De combinatie van gebruikersnaam en wachtwoord is onjuist.
            </div>
          )}

          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              id="username"
              name="username"
              placeholder="Gebruikersnaam"
              autoComplete="true"
            />
            <label htmlFor="username">Gebruikersnaam</label>
          </div>
          <div className="form-floating">
            <input
              type="password"
              className="form-control"
              id="password"
              name="password"
              placeholder="Wachtwoord"
              autoComplete="false"
            />
            <label htmlFor="password">Wachtwoord</label>
          </div>
          <div className="mb-3 form-check">
            <input
              type="checkbox"
              name="remember_me"
              className="form-check-input"
              id="rememberMe"
              defaultChecked="true"
            />
            <label className="form-check-label" htmlFor="rememberMe">
              Onthoud mij
            </label>
          </div>

          <button
            className="w-100 btn btn-lg btn-primary"
            type="submit"
            disabled={isLoading ? "disabled" : null}
          >
            {isLoading ? (
              <React.Fragment>
                <span
                  className="spinner-border spinner-border-sm me-1"
                  role="status"
                  aria-hidden="true"
                ></span>
                Inloggen...
              </React.Fragment>
            ) : (
              "Inloggen"
            )}
          </button>
        </form>
      </div>
    </div>
  );
}

export default SignIn;
