import {
  getCookieValue,
  setCookieValue,
  removeCookieValue,
} from "../../lib/cookie";
import AuthenticationService from "../../services/AuthenticationService";

const accessToken = getCookieValue("accessToken");
let user = null;

if (accessToken !== undefined) {
  const accessTokenPayload = JSON.parse(atob(accessToken.split(".")[1]));
  user = accessTokenPayload.user;
}

const authReducer = (
  state = {
    user,
  },
  action
) => {
  switch (action.type) {
    case "LOGIN":
      const tokenPayload = JSON.parse(
        atob(action.data.accessToken.split(".")[1])
      );

      setCookieValue("accessToken", action.data.accessToken, {
        expires: 30,
      });

      if (action.data.rememberMe) {
        setCookieValue("refreshToken", action.data.refreshToken, {
          expires: 30,
        });
      }

      state.user = tokenPayload.user;

      return state;

    case "REFRESH_TOKEN":
      if (getCookieValue("refreshToken") !== undefined) {
        const authenticationService = new AuthenticationService();
        authenticationService
          .refreshToken(getCookieValue("refreshToken"))
          .then((response) => {
            action.data.dispatch({
              type: "REFRESH_TOKEN_SUCCESS",
              data: response.data,
            });
          });
      }

      return state;

    case "REFRESH_TOKEN_SUCCESS":
      const { access_token, refreshToken } = action.data;
      const accessTokenPayload = JSON.parse(atob(access_token.split(".")[1]));

      setCookieValue("access_token", access_token, { expires: 30 });
      setCookieValue("refreshToken", refreshToken, { expires: 30 });

      state.user = accessTokenPayload.user;

      return state;

    case "LOGOUT":
      removeCookieValue("accessToken");
      removeCookieValue("refreshToken");
      state.user = null;

      return state;

    default:
      return state;
  }
};

export default authReducer;
