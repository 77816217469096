import React from "react";

function LoadingButton({ children, titleLoading, isLoading }) {
  return (
    <button
      className="btn btn-secondary"
      type="submit"
      disabled={isLoading ? "disabled" : null}
    >
      {isLoading ? (
        <React.Fragment>
          <span
            className="spinner-border spinner-border-sm me-1"
            role="status"
            aria-hidden="true"
          ></span>
          {titleLoading}
        </React.Fragment>
      ) : (
        children
      )}
    </button>
  );
}

export default LoadingButton;
