import React from "react";
import NavItemGroup from "./NavItemGroup";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

function NavSideBar() {
  const dispatch = useDispatch();
  const sidebarCollapsed = useSelector((state) => state.nav.sidebarCollapsed);
  // const user = useSelector((state) => state.auth.user);

  function handleSidebar() {
    dispatch({
      type: "TOGGLE_SIDEBAR",
    });
  }

  return (
    <nav
      className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top"
      id="mainNav"
    >
      <Link className="navbar-brand" to="/">
        Films & Series
      </Link>

      <button
        className="navbar-toggler navbar-toggler-right collapsed"
        type="button"
        onClick={handleSidebar}
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div
        className={`collapse navbar-collapse${sidebarCollapsed ? "" : " show"}`}
      >
        <ul className="navbar-nav navbar-sidenav">
          <NavItemGroup
            name="Films"
            icon="video"
            routes={[
              {
                name: "Downloadlijst",
                prefix: "/films/downloadlijst",
                to: "/films/downloadlijst",
              },
              {
                name: "Nog te kijken",
                prefix: "/films/nog-te-kijken",
                to: "/films/nog-te-kijken",
              },
              {
                name: "Gezien",
                prefix: "/films/gezien",
                to: "/films/gezien",
              },
            ]}
          />
          <NavItemGroup
            name="Series"
            icon="clapperboard"
            routes={[
              {
                name: "Voortgang",
                prefix: "/series/voortgang",
                to: "/series/voortgang",
              },
              {
                name: "Nieuw",
                prefix: "/series/nieuw",
                to: "/series/nieuw",
              },
              {
                name: "Gezien",
                prefix: "/series/gezien",
                to: "/series/gezien",
              },
            ]}
          />
          <NavItemGroup
            name={`Beheer`}
            icon="cog"
            routes={[
              {
                name: "Film updates",
                prefix: "/beheer/film-updates",
                to: "/beheer/film-updates",
              },
              {
                name: "Serie updates",
                prefix: "/beheer/serie-updates",
                to: "/beheer/serie-updates",
              },
            ]}
          />
          <NavItemGroup
            name={`Account`}
            icon="user"
            routes={[
              {
                name: "Uitloggen",
                prefix: "/account/uitloggen",
                to: "/account/uitloggen",
              },
            ]}
          />
        </ul>
      </div>
    </nav>
  );
}

export default NavSideBar;
