import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MovieService from "../../services/MovieService";
import Table from "react-bootstrap/Table";
import Paging from "../../components/Pagination/Paging";

function MovieUpdates() {
  const [data, setData] = useState(null);
  const [meta, setMeta] = useState(null);
  const [page, setPage] = useState(1);
  const movieService = new MovieService();

  function loadData() {
    movieService.getMovies(page, "updated", "desc").then((response) => {
      setMeta({
        total: response.data.total,
        limit: response.data.limit,
      });
      setData([...response.data.data]);
    });
  }

  useEffect(() => {
    loadData();
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="card mb-3 mt-5">
      <div className="card-header">
        <FontAwesomeIcon icon="table" /> Films
      </div>
      <div className="card-body">
        {data && (
          <React.Fragment>
            <h4>Film updates</h4>
            <Table style={{ minWidth: "750px" }}>
              <thead>
                <tr>
                  <th scope="col">Naam</th>
                  <th scope="col">Genre</th>
                  <th scope="col">Jaar</th>
                  <th scope="col">Categorie</th>
                  <th scope="col">Toegevoegd op</th>
                  <th scope="col">Gewijzigd op</th>
                </tr>
              </thead>
              <tbody>
                {data.map((movie, index) => {
                  return (
                    <tr key={index}>
                      <td>{movie.name}</td>
                      <td>{movie.genre.name}</td>
                      <td>{movie.year}</td>
                      <td>{movie.type.name}</td>
                      <td>{movie.added}</td>
                      <td>{movie.updated}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>

            {meta.total > meta.limit && (
              <div className="mt-2">
                <Paging
                  activePage={page}
                  total={meta.total}
                  limit={meta.limit}
                  setPage={setPage}
                />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

export default MovieUpdates;
