import React from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from "react-redux";

function NavItemGroup({ name, icon, routes }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const { pathname } = location;
  const activeNavGroup = useSelector((state) => state.nav.activeNavGroup);

  if (
    activeNavGroup === null &&
    pathname.startsWith(`/${name.replace(" ", "-").toLowerCase()}`)
  ) {
    dispatch({
      type: "ACTIVE_NAV_GROUP",
      data: {
        activeNavGroup: name,
      },
    });
  }

  function handleSidebar() {
    dispatch({
      type: "TOGGLE_SIDEBAR",
    });
  }

  function handleNavGroup(name) {
    dispatch({
      type: "ACTIVE_NAV_GROUP",
      data: { activeNavGroup: activeNavGroup !== name ? name : "" },
    });
  }

  return (
    <li className="nav-item" title={name}>
      <div
        className={`nav-link nav-link-collapse${
          activeNavGroup === name ? "" : " collapsed"
        }`}
        onClick={() => handleNavGroup(name)}
      >
        <FontAwesomeIcon icon={icon} />{" "}
        <span className="nav-link-text">{name}</span>
      </div>
      <ul
        className={`sidenav-second-level collapse${
          activeNavGroup === name ? " show" : ""
        }`}
      >
        {routes.map((route, index) => {
          return (
            <li
              key={index}
              className={pathname.startsWith(route.prefix) ? "active" : ""}
            >
              <Link to={route.to} onClick={handleSidebar}>
                {route.name}
              </Link>
            </li>
          );
        })}
      </ul>
    </li>
  );
}

export default NavItemGroup;
