import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import LoadingButton from "../Button/LoadingButton";
import SerieService from "../../services/SerieService";

function SerieForm({ serie, types, isLoading, setSerie, handleSubmit }) {
  let navigate = useNavigate();

  return (
    <form method="POST" onSubmit={handleSubmit}>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="1">
          Naam
        </Form.Label>
        <Col sm="2">
          <Form.Control
            name="name"
            type="text"
            onChange={(e) => {
              setSerie({ ...serie, name: e.target.value });
            }}
            value={serie.name === null ? "" : serie.name}
            autoComplete="off"
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="1">
          Seizoen
        </Form.Label>
        <Col sm="2">
          <Form.Control
            name="season"
            type="number"
            onChange={(e) => {
              setSerie({ ...serie, season: e.target.value });
            }}
            value={serie.season === null ? "" : serie.season}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="1">
          Aflevering
        </Form.Label>
        <Col sm="2">
          <Form.Control
            name="episode"
            type="number"
            onChange={(e) => {
              setSerie({ ...serie, episode: e.target.value });
            }}
            value={serie.episode === null ? "" : serie.episode}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="1">
          DVD
        </Form.Label>
        <Col sm="2">
          <Form.Control
            name="dvd"
            type="number"
            onChange={(e) => {
              setSerie({ ...serie, dvd: e.target.value });
            }}
            value={serie.dvd === null ? "" : serie.dvd}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="1">
          Seizoen DL
        </Form.Label>
        <Col sm="2">
          <Form.Control
            name="dlSeasonTill"
            type="number"
            onChange={(e) => {
              setSerie({ ...serie, dlSeasonTill: e.target.value });
            }}
            value={serie.dlSeasonTill === null ? "" : serie.dlSeasonTill}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="1">
          Aflev. DL
        </Form.Label>
        <Col sm="2">
          <Form.Control
            name="dlEpisodeTill"
            type="number"
            onChange={(e) => {
              setSerie({ ...serie, dlEpisodeTill: e.target.value });
            }}
            value={serie.dlEpisodeTill === null ? "" : serie.dlEpisodeTill}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="1">
          Negeer taal
        </Form.Label>
        <Col sm="">
          <Form.Check
            style={{ paddingTop: "7px" }}
            type="checkbox"
            id="dlIgnoreLanguage"
            onChange={(e) => {
              setSerie({
                ...serie,
                dlIgnoreLanguage: e.target.checked ? true : null,
              });
            }}
            checked={serie.dlIgnoreLanguage === true}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="1">
          Categorie
        </Form.Label>
        <Col sm="2">
          <Form.Select
            name="type"
            value={serie.type.id}
            onChange={(e) => {
              if (parseInt(e.target.value) !== SerieService.SERIES_NEW) {
                setSerie({
                  ...serie,
                  type: { id: e.target.value },
                  dlSeasonTill: null,
                  dlEpisodeTill: null,
                });
              } else {
                setSerie({
                  ...serie,
                  type: { id: e.target.value },
                  dlSeasonTill: 1,
                  dlEpisodeTill: 0,
                });
              }
            }}
          >
            {types.map((type, index) => (
              <option key={index} value={type.id}>
                {type.name}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Form.Group>

      <Button
        variant="outline-dark"
        className="me-3"
        onClick={(e) => {
          navigate(-1);
        }}
      >
        Terug
      </Button>

      <LoadingButton titleLoading="Opslaan.." isLoading={isLoading}>
        Opslaan
      </LoadingButton>
    </form>
  );
}

export default SerieForm;
