import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import LoadingButton from "../../components/Button/LoadingButton";

function MovieForm({
  movie,
  genres,
  types,
  kinds,
  isLoading,
  setMovie,
  handleSubmit,
}) {
  const months = [
    "Januari",
    "Februari",
    "Maart",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Augustus",
    "September",
    "Oktober",
    "November",
    "December",
  ];

  return (
    <form method="POST" onSubmit={handleSubmit}>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="1">
          Naam
        </Form.Label>
        <Col sm="2">
          <Form.Control
            name="name"
            type="text"
            onChange={(e) => {
              setMovie({ ...movie, name: e.target.value });
            }}
            value={movie.name === null ? "" : movie.name}
            autoComplete="off"
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="1">
          Genre
        </Form.Label>
        <Col sm="2">
          <Form.Select
            name="genre"
            value={movie.genre.id}
            onChange={(e) => {
              setMovie({ ...movie, genre: { id: e.target.value } });
            }}
          >
            {genres.map((genre, index) => (
              <option key={index} value={genre.id}>
                {genre.name}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="1">
          Maand
        </Form.Label>
        <Col sm="2">
          <Form.Select
            name="month"
            value={movie.month === null ? "" : movie.month}
            onChange={(e) => {
              setMovie({
                ...movie,
                month: e.target.value !== "" ? e.target.value : null,
              });
            }}
          >
            <option></option>
            {months.map((month, index) => (
              <option key={index} value={index + 1}>
                {month}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="1">
          Jaar
        </Form.Label>
        <Col sm="2">
          <Form.Control
            name="year"
            type="number"
            onChange={(e) => {
              setMovie({ ...movie, year: e.target.value });
            }}
            value={movie.year === null ? "" : movie.year}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="1">
          Soort
        </Form.Label>
        <Col sm="2">
          <Form.Select
            name="kind"
            value={movie.kind === null ? "" : movie.kind.id}
            onChange={(e) => {
              setMovie({
                ...movie,
                kind: e.target.value !== "" ? { id: e.target.value } : null,
              });
            }}
          >
            <option></option>
            {kinds.map((kind, index) => (
              <option key={index} value={kind.id}>
                {kind.name}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="1">
          Opmerking
        </Form.Label>
        <Col sm="2">
          <Form.Control
            name="remark"
            type="text"
            onChange={(e) => {
              setMovie({
                ...movie,
                remark: e.target.value !== "" ? e.target.value : null,
              });
            }}
            value={movie.remark === null ? "" : movie.remark}
            autoComplete="off"
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="1">
          Maand DL
        </Form.Label>
        <Col sm="2">
          <Form.Select
            name="month_dl"
            value={movie.monthDl === null ? "" : movie.monthDl}
            onChange={(e) => {
              setMovie({
                ...movie,
                monthDl: e.target.value !== "" ? e.target.value : null,
              });
            }}
            disabled={movie.id === undefined}
          >
            <option></option>
            {months.map((month, index) => (
              <option key={index} value={index + 1}>
                {month}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="1">
          Jaar DL
        </Form.Label>
        <Col sm="2">
          <Form.Control
            name="year_dl"
            type="number"
            onChange={(e) => {
              setMovie({
                ...movie,
                yearDl: e.target.value !== "" ? e.target.value : null,
              });
            }}
            value={movie.yearDl === null ? "" : movie.yearDl}
            disabled={movie.id === undefined}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="1">
          Categorie
        </Form.Label>
        <Col sm="2">
          <Form.Select
            name="type"
            value={movie.type.id}
            onChange={(e) => {
              if (parseInt(e.target.value) > 3) {
                setMovie({
                  ...movie,
                  type: { id: e.target.value },
                  month: null,
                });
              } else {
                setMovie({ ...movie, type: { id: e.target.value } });
              }
            }}
          >
            {types.map((type, index) => (
              <option key={index} value={type.id}>
                {type.name}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Form.Group>

      <LoadingButton titleLoading="Opslaan.." isLoading={isLoading}>
        Opslaan
      </LoadingButton>
    </form>
  );
}

export default MovieForm;
