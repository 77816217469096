import { get, post, put, del } from "../lib/http";

class MovieService {
  getMovieById(id) {
    return get(`/movies/${id}`);
  }

  getMovies(page, sortBy, sortDirection) {
    return get(`/movies`, {
      params: { page: page, sortBy: sortBy, sortDirection: sortDirection },
    });
  }

  getMoviesByType(type, page, name) {
    return get(`/movies/type/${type}`, {
      params: { name: name, page: page },
    });
  }

  addMovie(movie) {
    return post("/movies", movie);
  }

  updateMovie(movie) {
    return put(`/movies/${movie.id}`, movie);
  }

  promoteMovie(id) {
    return put(`/movies/${id}/promote`);
  }

  deleteMovie(id) {
    return del(`/movies/${id}`);
  }
}

export default MovieService;
