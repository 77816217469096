const navReducer = (
  state = {
    sidebarCollapsed: true,
    activeNavGroup: null,
  },
  action
) => {
  switch (action.type) {
    case "TOGGLE_SIDEBAR":
      state.sidebarCollapsed = !state.sidebarCollapsed;

      return state;

    case "ACTIVE_NAV_GROUP":
      state.activeNavGroup = action.data.activeNavGroup;

      return state;

    default:
      return state;
  }
};

export default navReducer;
