import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

function Logout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: "LOGOUT" });
    navigate("/inloggen");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
}

export default Logout;
