import { combineReducers } from "redux";
import authReducer from "./reducers/auth.reducer";
import navReducer from "./reducers/nav.reducer";

const appReducers = combineReducers({
  auth: authReducer,
  nav: navReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    return appReducers(undefined, action);
  }

  return appReducers(state, action);
};

export default rootReducer;
