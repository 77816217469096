import { Route, Routes, Navigate } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import NavSideBar from "../components/Navigation/NavSidebar";
import ProgressList from "./Series/ProgressListSeries";
import EditMovie from "./Movies/EditMovie";
import AddMovie from "./Movies/AddMovie";
import SeenListMovies from "./Movies/SeenListMovies";
import SeenListSeries from "./Series/SeenListSeries";
import DownloadListMovies from "./Movies/DownloadListMovies";
import ToWatchListMovies from "./Movies/ToWatchListMovies";
import NewListSeries from "./Series/NewListSeries";
import AddSerie from "./Series/AddSerie";
import EditSerie from "./Series/EditSerie";
import SignIn from "./SignIn/SignIn";
import Logout from "./Account/Logout";
import { useSelector } from "react-redux";
import MovieUpdates from "./Management/MovieUpdates";
import SerieUpdates from "./Management/SerieUpdates";

library.add(fas);

function App() {
  const user = useSelector((state) => state.auth.user);

  if (user === null) {
    return (
      <Routes>
        <Route path="/inloggen" element={<SignIn />} />
        <Route path="*" element={<Navigate to="/inloggen" />} />
      </Routes>
    );
  }

  return (
    <div className="fixed-nav bg-dark">
      <NavSideBar />

      <div className="content-wrapper vh-100">
        <Routes>
          <Route path="/films/downloadlijst" element={<DownloadListMovies />} />
          <Route path="/films/nog-te-kijken" element={<ToWatchListMovies />} />
          <Route path="/films/Gezien" element={<SeenListMovies />} />
          <Route path="/films/toevoegen" element={<AddMovie />} />
          <Route path="/films/:movieId" element={<EditMovie />} />
          <Route path="/series/voortgang" element={<ProgressList />} />
          <Route path="/series/gezien" element={<SeenListSeries />} />
          <Route path="/series/nieuw" element={<NewListSeries />} />
          <Route path="/series/toevoegen" element={<AddSerie />} />
          <Route path="/series/:serieId" element={<EditSerie />} />
          <Route path="/beheer/film-updates" element={<MovieUpdates />} />
          <Route path="/beheer/serie-updates" element={<SerieUpdates />} />
          <Route path="/account/uitloggen" element={<Logout />} />
          <Route path="*" element={<Navigate to="/films/downloadlijst" />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
