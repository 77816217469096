import { post } from "../lib/http";

class AuthenticationService {
  login(username, password) {
    return post("/authentication/token", {
      username,
      password,
    });
  }

  refreshToken(refreshToken) {
    return post("/authentication/token/refresh", {
      refreshToken: refreshToken,
    });
  }
}

export default AuthenticationService;
