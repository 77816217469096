import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MovieService from "../../services/MovieService";
import GenreService from "../../services/GenreService";
import KindService from "../../services/KindService";
import TypeService from "../../services/TypeService";
import MovieForm from "../../components/Forms/MovieForm";

function AddMovie() {
  const [isLoading, setIsLoading] = useState(true);
  const INITIAL_MOVIE = {
    name: null,
    genre: { id: 1 },
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    kind: null,
    remark: null,
    monthDl: null,
    yearDl: null,
    type: { id: 3 },
  };
  const [movie, setMovie] = useState(INITIAL_MOVIE);
  const [genres, setGenres] = useState(null);
  const [kinds, setKinds] = useState(null);
  const [types, setTypes] = useState(null);
  const [error, setError] = useState(null);
  const movieService = new MovieService();
  const genreService = new GenreService();
  const kindService = new KindService();
  const typeService = new TypeService();

  function loadData() {
    const genresPromise = genreService.getGenres();
    const kindsPromise = kindService.getKinds();
    const typesPromise = typeService.getMovieTypes();

    setIsLoading(true);

    Promise.all([genresPromise, kindsPromise, typesPromise]).then(
      (responses) => {
        setGenres(responses[0].data.data);
        setKinds(responses[1].data.data);
        setTypes(responses[2].data.data);

        setIsLoading(false);
      }
    );
  }

  function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);

    movieService
      .addMovie(movie)
      .then((response) => {
        setMovie(INITIAL_MOVIE);
        setError(false);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error.response.data.error.message);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="card mb-3 mt-5">
      <div className="card-header">
        <FontAwesomeIcon icon="cog" /> Films
      </div>
      <div className="card-body">
        {genres && kinds && types ? (
          <React.Fragment>
            <h5>Voeg toe {movie.name}</h5>

            <div className="mt-4">
              {error && (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              )}

              {error === false && (
                <div className="alert alert-success" role="alert">
                  Succesvol opgeslagen
                </div>
              )}
            </div>

            <MovieForm
              movie={movie}
              genres={genres}
              types={types}
              kinds={kinds}
              isLoading={isLoading}
              setMovie={setMovie}
              handleSubmit={handleSubmit}
            />
          </React.Fragment>
        ) : (
          "Laden.."
        )}
      </div>
    </div>
  );
}

export default AddMovie;
