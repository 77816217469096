import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MovieService from "../../services/MovieService";
import DropdownButton from "../../components/Button/DropdownButton";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Paging from "../../components/Pagination/Paging";

function ToWatchListMovies() {
  const MOVIES_TO_WATCH = 18;
  const [data, setData] = useState(null);
  const [meta, setMeta] = useState(null);
  const [page, setPage] = useState(1);
  const movieService = new MovieService();
  const navigate = useNavigate();

  function editMovie(props) {
    navigate(`/films/${props.id}`);
  }

  function deleteMovie(props) {
    if (window.confirm("Weet je zeker dat je deze film wilt verwijderen?")) {
      movieService.deleteMovie(props.id).then(() => {
        loadData();
      });
    }
  }

  function promoteMovie(props) {
    movieService.promoteMovie(props.id).then(() => {
      loadData();
    });
  }

  function loadData() {
    movieService.getMoviesByType(MOVIES_TO_WATCH, page).then((response) => {
      setMeta({
        total: response.data.total,
        limit: response.data.limit,
      });
      setData([...response.data.data]);
    });
  }

  function lookupImdb(props) {
    window.open(
      `https://www.imdb.com/find?q=${encodeURIComponent(props.name).replaceAll(
        "%20",
        "+"
      )}`
    );
  }

  function lookupMovieMeter(props) {
    window.open(`https://www.moviemeter.nl/site/zoeken/${props.name}`);
  }

  useEffect(() => {
    loadData();
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="card mb-3 mt-5">
      <div className="card-header">
        <FontAwesomeIcon icon="table" /> Films
      </div>
      <div className="card-body">
        {data && (
          <React.Fragment>
            <h4>Nog te kijken ({meta.total})</h4>
            <Table style={{ minWidth: "750px" }}>
              <thead>
                <tr>
                  <th scope="col">Naam</th>
                  <th scope="col">Genre</th>
                  <th scope="col">Jaar</th>
                </tr>
              </thead>
              <tbody>
                {data.map((movie, index) => {
                  return (
                    <tr key={index}>
                      <td>{movie.name}</td>
                      <td>{movie.genre.name}</td>
                      <td>{movie.year}</td>
                      <td>
                        <DropdownButton
                          title="Acties"
                          items={[
                            {
                              title: "Wijzig",
                              onClick: editMovie,
                              onClickProps: { id: movie.id },
                            },
                            {
                              title: "Verwijder",
                              onClick: deleteMovie,
                              onClickProps: { id: movie.id },
                            },
                            {
                              title: "Promote",
                              onClick: promoteMovie,
                              onClickProps: { id: movie.id },
                            },
                            {
                              title: "IMDb",
                              onClick: lookupImdb,
                              onClickProps: { name: movie.name },
                            },
                            {
                              title: "Moviemeter",
                              onClick: lookupMovieMeter,
                              onClickProps: { name: movie.name },
                            },
                          ]}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>

            {meta.total > meta.limit && (
              <div className="mt-2">
                <Paging
                  activePage={page}
                  total={meta.total}
                  limit={meta.limit}
                  setPage={setPage}
                />
              </div>
            )}

            <Button
              variant="secondary"
              onClick={() => {
                navigate("/films/toevoegen");
              }}
            >
              Toevoegen
            </Button>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

export default ToWatchListMovies;
