import { get } from "../lib/http";

class TypeService {
  getMovieTypes() {
    return get("/types/movie");
  }

  getSerieTypes() {
    return get("/types/serie");
  }
}

export default TypeService;
