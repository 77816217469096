import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MovieService from "../../services/MovieService";
import GenreService from "../../services/GenreService";
import KindService from "../../services/KindService";
import TypeService from "../../services/TypeService";
import MovieForm from "../../components/Forms/MovieForm";

function EditMovie() {
  const { movieId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [movie, setMovie] = useState(null);
  const [genres, setGenres] = useState(null);
  const [kinds, setKinds] = useState(null);
  const [types, setTypes] = useState(null);
  const [error, setError] = useState(null);
  const movieService = new MovieService();
  const genreService = new GenreService();
  const kindService = new KindService();
  const typeService = new TypeService();

  function loadData() {
    const moviePromise = movieService.getMovieById(movieId);
    const genresPromise = genreService.getGenres();
    const kindsPromise = kindService.getKinds();
    const typesPromise = typeService.getMovieTypes();

    setIsLoading(true);

    Promise.all([moviePromise, genresPromise, kindsPromise, typesPromise]).then(
      (responses) => {
        setMovie(responses[0].data.data);
        setGenres(responses[1].data.data);
        setKinds(responses[2].data.data);
        setTypes(responses[3].data.data);

        setIsLoading(false);
      }
    );
  }

  function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);

    movieService
      .updateMovie(movie)
      .then(() => {
        movieService.getMovieById(movieId).then((response) => {
          setMovie(response.data.data);
          setError(false);
          setIsLoading(false);
        });
      })
      .catch((error) => {
        setError(error.response.data.error.message);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="card mb-3 mt-5">
      <div className="card-header">
        <FontAwesomeIcon icon="cog" /> Films
      </div>
      <div className="card-body">
        {movie && genres && kinds && types ? (
          <React.Fragment>
            <h5>Wijzig {movie.name}</h5>

            <div className="mt-4">
              {error && (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              )}

              {error === false && (
                <div className="alert alert-success" role="alert">
                  Succesvol opgeslagen
                </div>
              )}
            </div>

            <MovieForm
              movie={movie}
              genres={genres}
              types={types}
              kinds={kinds}
              isLoading={isLoading}
              setMovie={setMovie}
              handleSubmit={handleSubmit}
            />
          </React.Fragment>
        ) : (
          "Laden.."
        )}
      </div>
    </div>
  );
}

export default EditMovie;
