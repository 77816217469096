import React from "react";
import Pagination from "react-bootstrap/Pagination";

function Paging({ activePage, total, limit, setPage }) {
  const totalPages = Math.ceil(total / limit);
  const pagesRemaining = totalPages - activePage;

  let pagesLeft = activePage <= 3 ? activePage - 2 : 2;
  let pagesRight = pagesLeft > 0 ? 4 - pagesLeft : 4;

  pagesLeft =
    pagesRemaining > 0 && pagesRemaining < 2 ? pagesLeft + 1 : pagesLeft;
  pagesLeft = activePage === totalPages ? pagesLeft + 1 : pagesLeft;
  pagesLeft = totalPages === 6 ? pagesLeft + 1 : pagesLeft;
  pagesRight = activePage > 1 && activePage < 4 ? pagesRight - 1 : pagesRight;

  const pages = [];

  for (let i = activePage - pagesLeft; i <= activePage + pagesRight; i++) {
    if (i > 1 && i < totalPages) {
      pages.push(i);
    }
  }

  return (
    <Pagination>
      {/* <Pagination.First onClick={() => setPage(1)} /> */}
      <Pagination.Prev
        disabled={activePage === 1}
        onClick={() => setPage(activePage - 1)}
      />
      <Pagination.Item active={activePage === 1} onClick={() => setPage(1)}>
        1
      </Pagination.Item>

      {activePage > 4 && !pages.includes(2) && <Pagination.Ellipsis />}

      {pages.map((page) => {
        return (
          <Pagination.Item
            key={page}
            active={activePage === page}
            onClick={() => setPage(page)}
          >
            {page}
          </Pagination.Item>
        );
      })}

      {totalPages - activePage > 3 && !pages.includes(totalPages - 1) && (
        <Pagination.Ellipsis />
      )}

      {totalPages > 1 && (
        <Pagination.Item
          active={activePage === totalPages}
          onClick={() => setPage(totalPages)}
        >
          {totalPages}
        </Pagination.Item>
      )}

      <Pagination.Next
        disabled={activePage === totalPages}
        onClick={() => setPage(activePage + 1)}
      />
      {/* <Pagination.Last onClick={() => setPage(totalPages)} /> */}
    </Pagination>
  );
}

export default Paging;
