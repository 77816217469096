import Dropdown from "react-bootstrap/Dropdown";

function DropdownButton({ title, items }) {
  return (
    <Dropdown>
      <Dropdown.Toggle size="sm" variant="secondary" id="dropdown-basic">
        {title}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {items.map((item, key) => {
          return (
            <Dropdown.Item
              key={key}
              onClick={() => item.onClick(item.onClickProps)}
            >
              {item.title}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DropdownButton;
