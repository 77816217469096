import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SerieService from "../../services/SerieService";
import TypeService from "../../services/TypeService";
import SerieForm from "../../components/Forms/SerieForm";

function AddSerie() {
  const [isLoading, setIsLoading] = useState(true);
  const INITIAL_SERIE = {
    name: null,
    season: 1,
    dvd: null,
    episode: 0,
    dlSeasonTill: 1,
    dlEpisodeTill: 0,
    dlIgnoreLanguage: false,
    type: { id: 15 },
  };
  const [serie, setSerie] = useState(INITIAL_SERIE);
  const [types, setTypes] = useState(null);
  const [error, setError] = useState(null);
  const serieService = new SerieService();
  const typeService = new TypeService();

  function loadData() {
    setIsLoading(true);

    typeService.getSerieTypes().then((response) => {
      setTypes(response.data.data);
      setIsLoading(false);
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);

    serieService
      .addSerie(serie)
      .then((response) => {
        setSerie(INITIAL_SERIE);
        setError(false);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error.response.data.error.message);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="card mb-3 mt-5">
      <div className="card-header">
        <FontAwesomeIcon icon="cog" /> Series
      </div>
      <div className="card-body">
        {types ? (
          <React.Fragment>
            <h5>Voeg toe {serie.name}</h5>

            <div className="mt-4">
              {error && (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              )}

              {error === false && (
                <div className="alert alert-success" role="alert">
                  Succesvol opgeslagen
                </div>
              )}
            </div>

            <SerieForm
              serie={serie}
              types={types}
              isLoading={isLoading}
              setSerie={setSerie}
              handleSubmit={handleSubmit}
            />
          </React.Fragment>
        ) : (
          "Laden.."
        )}
      </div>
    </div>
  );
}

export default AddSerie;
